import {ApptSlot} from "@services/monolith/availability";
import {useEffect, useState} from "react";

import {ApptSlotBlock} from "../_services/types";
import {SpecialtyId} from "../constants/specialtyIds";
import {LocationForFetchCachedSlot, fetchCachedSlotAround} from "../utils/fetchCachedSlotAround";

export const getSoonestSlotFromApptSlotBlock = (slotTimes: ApptSlotBlock[]): number =>
  slotTimes?.[0]?.[0]?.time;

export const useGetSoonestInPracticeBySpecialtyId = ({
  specialtyId,
  locations,
  locationsSorted,
}: {
  specialtyId: SpecialtyId;
  locations: LocationForFetchCachedSlot[];
  locationsSorted: boolean;
}): Partial<ApptSlot | null> => {
  const [slot, setSlot] = useState<Partial<ApptSlot>>({});

  useEffect(() => {
    if (locationsSorted) {
      fetchCachedSlotAround({locations, specialtyId}).then(setSlot);
    }
  }, [locations, specialtyId, locationsSorted]);

  return slot || null;
};

export type SoonestSlotTimeByLocationAndSpecialty = {
  [locationId: string]: {
    [specialtyId: string]: number;
  };
};
