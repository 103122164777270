import {ApptSlot} from "@services/monolith/availability";

import {ApptSlotsWorkerParams} from "../../workers/common/types";
import {RootStateLocation} from "../store/types";
import {filterLocationsBySpecialtyId} from "./filterLocationsBySpecialtyId";
import {isVirtual} from "./isVirtual";
import {fetchCachedSlot} from "./slots";

export type LocationForFetchCachedSlot = Pick<RootStateLocation, "id" | "specialtyIds">;

// eg:
// fetchCachedSlotAround({locations, specialtyId: SpecialtyId.URGENT_CARE, locationCount: 5})

// use this only for clinics ,no virtual
export const fetchCachedSlotAround = (
  args: ApptSlotsWorkerParams & {locations: LocationForFetchCachedSlot[]; locationCount?: number},
): Promise<Partial<ApptSlot>> => {
  const {locations, specialtyId, locationCount = 3} = args;
  // @ts-expect-error TS2345: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
  const locationsWithSpecialtyId = isVirtual(specialtyId)
    ? [locations?.[0]]
    : // @ts-expect-error TS2345: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
      filterLocationsBySpecialtyId(locations, specialtyId, locationCount);
  // @ts-expect-error TS2322: Type 'Promise<Partial<ApptSlot> | {} | undefined>' is not assignable to type 'Promise<Partial<ApptSlot>>'.
  return locationsWithSpecialtyId
    .map(location => fetchCachedSlot({...args, locationId: location.id}))
    .sequence()
    .then(slots => (slots?.length ? slots.sortBy(slot => slot?.time)[0] : {}));
};
